  <template>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
      <div class="container p-5  ">
        <div class="row d-flex justify-content-center  ">
          <div class="col-sm-10 border rounded  ">
            <div class="row  mt-3 d-flex justify-content-center   " v-for="(ques, index) in question">
              <div class="col-sm-8 mt-3   " style="text-align:left;">

                <h4 style="font-size:20px;" v-if="index != 0"> {{ ques }} </h4>

                <div v-for="(que1, index1) in data2" class="">

                  <!-- <span  v-if="que1.id==1" >{{Object.values(this.data2[0])}} </span> -->
                  <div class="" v-for="(ans, ind) in Object.values(this.data2[index1])">
                    <p class="mt-3 form-control" v-if="que1.id == wid && ind != 0 && ind == index" id="">{{ ans }}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { DeviceUUID } from 'device-uuid';
  export default {
    data() {
      return {
        userID: '',
        localtoken: '',
        deviceID: '',
        link: 'https://backend.smkedugroup.com',
        question:
          [
            'Form ဖြည့်သည့်ရက်စွဲ',
            'ကျောင်းသား/သူ၏အမည်',
            'ကျား/မ',
            'ကျောင်းသား/သူ၏ မွေးသက္ကရာဇ်',
            'စုံစမ်းလိုသည့်အတန်း',
            'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်း',
            'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်းရှိလျှင် အမည် နှင့် အတန်း',
            'ကျောင်းသား/သူ၏ လူမျိုး/ဘာသာ',
            'ဖခင်အမည်နှင့်အလုပ်အကိုင်',
            'မိခင်အမည်နှင့်အလုပ်အကိုင်',
            'အုပ်ထိန်းသူ၏အမည်နှင့်တော်စပ်ပုံ',
            'မိဘ/အုပ်ထိန်းသူ၏ဖုန်းနံပါတ်',
            'မိဘ/အုပ်ထိန်းသူ၏နေရပ်လိပ်စာ',
            '၁။ ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်းကို မည်ကဲ့သို့သိရှိပါသနည်း။ မည်သူ့အဆက်အသွယ် နှင့် သိရှိခဲ့ ပါသနည်း။',
            '၂။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏သား/သမီးများအပေါ် ထားရှိသည့် မျှော်မှန်းချက် နှင့် ကျောင်းအပေါ် ထားရှိသည့် ရည်ရွယ်ချက်။',
            '၃။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏ သား/ သမီး များအပေါ် ပညာရေးနှင့်ပတ်သက်၍ မည်ကဲ့သို့ပံ့ပိုး ပေးနိုင်ပါသနည်း။    ',
            '၄။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိတို့၏ သား/သမီးများကို ပြည်သူ့နီတိနှင့်ပတ်သက်၍ မည်ကဲ့သို့  သင်ကြား ပေးထားပါသနည်း။',
            '၅။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိ သား/ သမီးများ၏ ပညာရေးနှင့်ပတ်သက်၍ ကျောင်းသား/ မိဘ/ ဆရာ သုံးဦး သုံးဖလှယ် ပူးပေါင်းမှု ပြုနိုင်ပါသလား။ မည်ကဲ့သို့ပြုလုပ်ပါမည်နည်း။',
            '၆။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိကလေး၏ ကျန်းမာရေးအခြေအနေကို ကျောင်းရှိ ဆရာ/ ဆရာမ များနှင့် ပွင့်လင်းစွာ ဆွေးနွေးတင်ပြမှု ပြုလုပ်နိုင်ပါသလား။',
            '၇။ ကျောင်းဘက်မှသိထားသင့်သော မိမိကလေး၏ ကျန်းမာရေးနှင့်ပတ်သက်၍ အောက်ဖော်ပြပါအချက်များနှင့် ကိုက်ညီမှုရှိခဲ့ပါက ထိုအချက်များကို အမှန်ခြစ်ပေးပါရန်။',
            '၈။ ကလေးများ၏ကျန်းမာရေးနှင့်ပတ်သက်၍ အခြားသိထားသင့်သောအချက်များရှိပါက ဖြည့်စွက်ပေးရန်။',
            '၉။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် Social Media (Facebook) ကို မည်ကဲ့သို့အသုံးပြုပါသနည်း။',
            '၁၀။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်း၏ စည်းကမ်းပိုင်းနှင့်  ပတ်သက်၍ မည်မျှအထိ သိရှိနားလည်ထားပါသနည်း။',
            ' ၁၁။  ရွှေမော်ကွန်း ကိုယ်ပိုင် အလယ်တန်းကျောင်း မှ ချမှတ်ထားသော စည်းကမ်းချက်များအား မိဘ/အုပ်ထိန်းသူများ ဘက်မှ ပူးပေါင်းလိုက်နာ ဆောင်ရွက်နိုင်ပါ သလား။',
            'Form ဖြည့်သူ၏အမည် ၊ တော်စပ်ပုံနှင့် ဖုန်းနံပါတ်',
            'Form ဖြည့်သူ၏ Email'
          ],
        question1:
          [
            'Form ဖြည့်သည့်ရက်စွဲ',
            'ကျောင်းသား/သူ၏အမည်',
            'ကျား/မ',
            'ကျောင်းသား/သူ၏ မွေးသက္ကရာဇ်',
            'စုံစမ်းလိုသည့်အတန်း',
            'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်း',
            'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်းရှိလျှင် အမည် နှင့် အတန်း',
            'ကျောင်းသား/သူ၏ လူမျိုး/ဘာသာ',
            'ဖခင်အမည်နှင့်အလုပ်အကိုင်',
            'မိခင်အမည်နှင့်အလုပ်အကိုင်',
            'အုပ်ထိန်းသူ၏အမည်နှင့်တော်စပ်ပုံ',
            'မိဘ/အုပ်ထိန်းသူ၏ဖုန်းနံပါတ်',
            'မိဘ/အုပ်ထိန်းသူ၏နေရပ်လိပ်စာ',
            '၁။ ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်းကို မည်ကဲ့သို့သိရှိပါသနည်း။ မည်သူ့အဆက်အသွယ် နှင့် သိရှိခဲ့ ပါသနည်း။',
            '၂။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏သား/သမီးများအပေါ် ထားရှိသည့် မျှော်မှန်းချက် နှင့် ကျောင်းအပေါ်                     ထားရှိသည့် ရည်ရွယ်ချက်။',
            '၃။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏ သား/ သမီး များအပေါ် ပညာရေးနှင့်ပတ်သက်၍ မည်ကဲ့သို့ပံ့ပိုး ပေးနိုင်ပါသနည်း။    ',
            '၄။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိတို့၏ သား/သမီးများကို ပြည်သူ့နီတိနှင့်ပတ်သက်၍ မည်ကဲ့သို့  သင်ကြား ပေးထားပါသနည်း။',
            '၅။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိ သား/ သမီးများ၏ ပညာရေးနှင့်ပတ်သက်၍ ကျောင်းသား/ မိဘ/ ဆရာ သုံးဦး သုံးဖလှယ် ပူးပေါင်းမှု ပြုနိုင်ပါသလား။ မည်ကဲ့သို့ပြုလုပ်ပါမည်နည်း။',
            '၆။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိကလေး၏ ကျန်းမာရေးအခြေအနေကို ကျောင်းရှိ ဆရာ/ ဆရာမ များနှင့် ပွင့်လင်းစွာ ဆွေးနွေးတင်ပြမှု ပြုလုပ်နိုင်ပါသလား။',
            '၇။ ကျောင်းဘက်မှသိထားသင့်သော မိမိကလေး၏ ကျန်းမာရေးနှင့်ပတ်သက်၍ အောက်ဖော်ပြပါအချက်များနှင့် ကိုက်ညီမှုရှိခဲ့ပါက ထိုအချက်များကို အမှန်ခြစ်ပေးပါရန်။',
            '၈။ ကလေးများ၏ကျန်းမာရေးနှင့်ပတ်သက်၍ အခြားသိထားသင့်သောအချက်များရှိပါက ဖြည့်စွက်ပေးရန်။',
            '၉။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် Social Media (Facebook) ကို မည်ကဲ့သို့အသုံးပြုပါသနည်း။',
            '၁၀။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်း၏ စည်းကမ်းပိုင်းနှင့်  ပတ်သက်၍ မည်မျှအထိ သိရှိနားလည်ထားပါသနည်း။',
            ' ၁၁။  ရွှေမော်ကွန်း ကိုယ်ပိုင် အလယ်တန်းကျောင်း မှ ချမှတ်ထားသော စည်းကမ်းချက်များအား မိဘ/အုပ်ထိန်းသူများ ဘက်မှ ပူးပေါင်းလိုက်နာ ဆောင်ရွက်နိုင်ပါ သလား။',
            'Form ဖြည့်သူ၏အမည် ၊ တော်စပ်ပုံနှင့် ဖုန်းနံပါတ်',
            'Form ဖြည့်သူ၏ Email'
          ],
        data2: null,
        wid: '',

      }
    },
    methods: {
      alreadyLogin() {
        let article =
        {
          userID: this.userID,
          localtoken: this.localtoken,
          deviceID: this.deviceID,
        };
        axios.post(this.link + "/api/alreadyLogin", article)
          .then(response => {
            if (response.data.state == "failed") {
              window.location.assign("/login")
            }
            else if (response.data.state == "success") {
              localStorage.setItem("token", response.data.Token);
              localStorage.setItem("deviceID", response.data.deviceID);
              localStorage.setItem("userID", response.data.userid);
            }
          }
          )
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
      },
      show() {
        axios.get(this.link + "/api/waitinglist")
          .then(response => {
            this.data2 = response.data;
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      },
    },
    mounted() {
      if (!localStorage.getItem("deviceID")) {
        this.deviceID = new DeviceUUID().get();
      }
      else {
        this.deviceID = localStorage.getItem("deviceID");
      }
      if (!localStorage.getItem("token")) {
        this.localtoken = "Null";
      }
      else {
        this.localtoken = localStorage.getItem("token");
      }
      if (!localStorage.getItem("userID")) {
        this.userID = "Null";
      }
      else {
        this.userID = localStorage.getItem("userID");
      }
      this.alreadyLogin();
      this.show();
      var query = this.$route.query.wid;
      if (!query) {
        window.location.assign("waitinglistview")
      }
      else {
        this.wid = query;
      }
      console.log(this.$route.query.wid)
      console.log(this.data2)
    },
  }
  </script>

  <style lang="scss" scoped></style>