<template>
  <template v-if="isLoading">
    Loading ....
  </template>
  <template v-if="!isLoading">
    <div class="container">
      <div class="row my-5">
        <div class="col-12">
          <div class="d-flex justify-content-between mb-3">
            <h3 class="fw-bold">
              Course List
            </h3>
            <router-link :to="{ name: `CoursesRegisterCreate` }" class="btn btn-primary">Create</router-link>
          </div>
          <div class="d-flex align-items-end justify-content-start gap-3 mb-3">
            <div>
              <label class="form-label d-block text-start">Class Type</label>
              <select class="form-select" aria-label="Default select example" v-model="filter.type">
                <option :value="type" :key="index" v-for="(type, index) in classTypes">{{ type }}</option>
              </select>
            </div>
            <template v-if="filter.type === 'weekday classes'">
              <div>
                <label class="form-label d-block text-start">Year Level</label>
                <select class="form-select" aria-label="Default select example" style="width: 400px" v-model="filter.level">
                  <option :value="level" :key="index" v-for="(level, index) in yearLevels['one']">{{ level }}</option>
                </select>
              </div>
            </template>
            <template v-if="filter.type === 'weekend classes & summer classes'">
              <div>
                  <label class="form-label d-block text-start">Year Level</label>
                  <select class="form-select" aria-label="Default select example" style="width: 400px" v-model="filter.level">
                    <option :value="level" :key="index" v-for="(level, index) in yearLevels['two']">{{ level }}</option>
                  </select>
                </div>
            </template>
            <button class="btn btn-primary" @click="fetchCourses">Filter</button>
            <button class="btn btn-primary" @click="reload">Reload</button>
          </div>
          <div class="card p-3 pb-0">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Class Type</th>
                    <th scope="col">Year Level</th>
                    <th scope="col">Intake</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Order</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="courses.length > 0">
                    <tr v-for="(course, index) in courses" :key="course.id">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ course.class_type }}</td>
                      <td>{{ course.year_level }}</td>
                      <td>{{ course.intake }}</td>
                      <td>{{ course.fromMonth }}</td>
                      <td>{{ course.toMonth }}</td>
                      <td>{{ course.list_order }}</td>
                      <td class="text-nowrap">
                        <router-link :to="`/Admin/CoursesRegister/${course.id}/edit`"
                          class="btn btn-warning me-3">Edit</router-link>
                        <button type="button" class="btn btn-danger" :disabled="isDeleting"
                          @click="deleteCourse(course.id)">Delete</button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="text-center">
                      <td colspan="8"> No course exist!</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import courseService from "@/services/courseApi"
import { cusFormatDate } from "@/utils/index"
import { courseConstant } from "@/constants"

export default {
  data() {
    return {
      courses: [],
      isLoading: true,
      isDeleting: false,
      classTypes: courseConstant.classTypes,
      yearLevels: courseConstant.yearLevels,
      filter: {
        type: '',
        level: ''
      }
    }
  },
  methods: {
    cusFormatDate,
    async reload() {
      this.filter = {
        type: '',
        level: '',
      }
      await this.fetchCourses()
    },
    async fetchCourses() {
      let payload = undefined
      if (this.filter.type && this.filter.level) {
        payload = {
          params: {
            type: this.filter.type,
            level: this.filter.level
          }
        }
      }
      let res = await courseService.getCourses(payload)
      console.log(res)
      this.courses = res
    },
    async deleteCourse(id) {
      let result = confirm("Want to delete?");
      if (result) {
        this.isDeleting = true
        try {
          let res = await courseService.deleteCourse({ id })
          this.fetchCourses()
        } catch (error) {
          console.log(error)
        } finally {
          this.isDeleting = false
        }
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchCourses()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>