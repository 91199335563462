<template>
  <nav class="cus_sub_nav navbar navbar-expand-lg navbar-light">
    <div
      class="container text-start justify-content-end justify-content-lg-start py-2 py-lg-0"
    >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-center w-100"
        id="navbarNav"
      >
        <ul class="navbar-nav justify-content-between text-center">
          <li class="nav-item mb-2 mt-2">
            <router-link
              :to="{ name: 'AdmissionOverview' }"
              class="nav-link"
              :class="{ 'fw-bold': isActiveLink('AdmissionOverview') }"
            >
              <span v-if="font_type == 1">School Enrollment Programme</span>
              <span v-if="font_type == 2">ကျောင်းအပ်လက်ခံရေးအစီအစဉ်</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link
              :to="{ name: 'RequiredDocumentation' }"
              class="nav-link"
              :class="{ 'fw-bold': isActiveLink('RequiredDocumentation') }"
            >
              <span v-if="font_type == 1">Required Documents</span>
              <span v-if="font_type == 2">လိုအပ်သောစာရွက်စာတမ်းများ</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link
              :to="{ name: 'Fees' }"
              class="nav-link"
              :class="{ 'fw-bold': isActiveLink('Fees') }"
            >
              <span v-if="font_type == 1">School Fees</span>
              <span v-if="font_type == 2">ကျောင်းလခ</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link
              :to="{ name: 'Faq' }"
              class="nav-link"
              :class="{ 'fw-bold': isActiveLink('Faq') }"
            >
              <span v-if="font_type == 1">Frequently Asked Questions</span>
              <span v-if="font_type == 2">အမေးအများဆုံး မေးခွန်း</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link
              :to="{ name: 'waitinglist' }"
              class="nav-link"
              :class="{ 'fw-bold': isActiveLink('waitinglist') }"
            >
              <span v-if="font_type == 1">Waiting List</span>
              <span v-if="font_type == 2">စောင့်ဆိုင်းစာရင်း</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    font_type: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    isActiveLink(name) {
      return this.$route.name === name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
