<template>
  <template v-if="isLoading">
    <div>Loading ...</div>
  </template>
  <template v-else>
    <AcademicLayout>
      <template #title>
        Pre-school (early years)
      </template>

      <template #header>
        <a v-for="each in payload" :key="each.id" :href="`#${each.id}`" class="btn me-3 rounded-pill">
          {{ each.intake }}
        </a>
      </template>

      <template #body>
        <div class="row label-bar">
          <div class="col-6">
            <div class="label py-3 text-uppercase text-center">
              intake
            </div>
          </div>
          <div class="col-6">
            <div class="label py-3 text-uppercase">area of learning</div>
          </div>
        </div>
        <Area v-for="area in payload" :area="area"></Area>
      </template>
    </AcademicLayout>
  </template>
</template>

<script>
import Area from '@/components/Academic/Area.vue'
import courseService from "@/services/courseApi"
import AcademicLayout from '@/views/academic/AcademicLayout.vue'
import { courseConstant } from "@/constants/index"

export default {
  components: {
    Area,
    AcademicLayout
  },
  data() {
    return {
      payload: [],
      fetchPayload: {
        params: {
          type: courseConstant.classTypes[0],
          level: courseConstant.yearLevels['one'][0]
        }
      }
    }
  },
  methods: {
    async fetchCourses() {
      let res = await courseService.getCourses(this.fetchPayload)
      console.log(res)
      res.map(each => {
        each.curriculum = JSON.parse(each.curriculum)
      })
      this.payload = res
      console.log(this.payload)
    },
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchCourses()
    } catch (err) {
      console.log(err)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  background-color: var(--cus-white);
  min-width: 200px;
  flex-shrink: 0;
  text-transform: uppercase;
  color: var(--cus-primary)
}

.label-bar {
  background-color: var(--cus-grey);
  border-bottom: 1px solid #808080;

  .label {
    font-size: var(--cus-fs-large);
    color: var(--cus-secondary-bold);
    font-weight: var(--cus-fw-semibold);
  }
}
</style>