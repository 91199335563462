export const courseConstant = {
  classTypes: [
        "weekday classes",
        "weekend classes & summer classes"
      ],
  yearLevels: {
    one: [
      'Pre-school(Early years)',
      'Primary',
      'Secondary'
    ],
    two: [
      'Pre-school(Early years)',
      'International Primary',
      'International Secondary'
    ],
  }
}