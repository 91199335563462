<template>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
        <h1 class="m-3">Teacher Category</h1>

        <div class="d-flex">
        <div class="card col-7 mt-5 ms-3">
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-sm-10 ms-1">
                <div class="container" style="max-width:100%; max-height:1000px; overflow:scroll;">
                    <table class="table table-bordered table-hover">
                        <thead style="position: sticky;top: 0;background-color: white;">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(teacher, index) in teachers" :key="teacher.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ teacher.name }}</td>
                                <td>{{ teacher.description }}</td>
                                <!-- <td style="text-align:left;">{{ teacher.position }}</td>
                                <td style="text-align:left;">{{ teacher.message }}</td> -->
                                <!-- <td>
                                    <img :src="teacher.teacher_photo_path" alt="" id="teacherimgphoto" style="width:60px; height:100px;">
                                </td>
                                <td class="d-flex justify-content-center">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" :id="'isDisplay' + teacher.id" :checked="teacher.isDisplay == '1'" @change="toggleDisplay(teacher.id)">
                                    </div>
                                </td> -->
                                <td>
                                    <button v-on:click="editModel(teacher.id)" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Edit</button>
                                    <button v-on:click="deleteTeacher(teacher.id)" class="btn btn-danger">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>

        <div class="category-card card col-4 mt-5 ms-5">
            <h2 class="mt-4 ms-4">Create Category Form</h2>
                    <form @submit.prevent="createCategory" class="p-4">
          <div class="mb-3">
            <label for="categoryName" class="form-label">Name</label>
            <input type="text" class="form-control" id="categoryName" v-model="newCategory.name" required>
          </div>
          <div class="mb-3">
            <label for="categoryDescription" class="form-label">Description</label>
            <textarea class="form-control" id="categoryDescription" v-model="newCategory.description" rows="3" required></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Create</button>
        </form>

        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// Create an Axios instance with your base URL and other configurations
const apiClient = axios.create({
    baseURL: 'https://backend.smkedugroup.com/api/teachercategory',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,  // Set a timeout for the request
});
export default {
    data() {
        return {
            link: "https://backend.smkedugroup.com/api/teachercategory",
            teachers: [],
            newCategory: {
        name: '',
        description: ''
      }

        };
    },
    mounted() {
        this.fetchTeachers();
    },
    methods: {
        async fetchTeachers() {
            try {
                const response = await axios.get(this.link);
                this.teachers = response.data;
            } catch (error) {
                console.error("Error fetching teachers:", error);
            }
        },
        async toggleDisplay(id) {
            try {
                const teacher = this.teachers.find(t => t.id === id);
                const newDisplayValue = teacher.isDisplay === '1' ? '0' : '1';
                await axios.put(`${this.link}/${id}`, { isDisplay: newDisplayValue });
                teacher.isDisplay = newDisplayValue;
            } catch (error) {
                console.error("Error updating display status:", error);
            }
        },
        editModel(id) {
            // Add your edit logic here
        },
        async deleteTeacher(id) {
            try {
                await axios.delete(`${this.link}/${id}`);
                this.teachers = this.teachers.filter(t => t.id !== id);
            } catch (error) {
                console.error("Error deleting teacher:", error);
            }
        },

        async createCategory() {
            try {
                const payload = new FormData();
                payload.append('name', this.newCategory.name);
                payload.append('description', this.newCategory.description);

                // Use the Axios instance to make the request
                const response = await apiClient.post(this.link, payload, {
                    headers: { 'Content-Type': 'multipart/form-data' } // Override the default header for FormData
                });

                this.teachers.push(response.data);

                // Show success alert
                Swal.fire({
                    icon: 'success',
                    title: 'Category Created!',
                    text: 'The category was created successfully.',
                    confirmButtonText: 'OK'
                });

                // Clear form fields
                this.newCategory.name = '';
                this.newCategory.description = '';
            } catch (error) {
                // Show error alert
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was an error creating the category. Please try again.',
                    confirmButtonText: 'OK'
                });

                // Log error to the console
                console.error("Error creating category:", error);

                // Optional: Display more details in the console
                if (error.response) {
                    console.error("Error details:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error message:", error.message);
                }
            }
        }


    }
}
</script>

<style>
    .category-card {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
</style>
