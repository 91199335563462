<template>
  <div class="container">
    <div class="row my-5">
      <div class="col-12">
        <div class="d-flex justify-content-between mb-3">
          <h3 class="fw-bold">
            Course Create
          </h3>
        </div>
        <div class="card p-3 pb-0">
          <form action="">
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label d-block text-start">Class Type</label>
                <select class="form-select" aria-label="Default select example" v-model="payload.class_type">
                  <option :value="type" :key="index" v-for="(type, index) in classTypes">{{ type }}</option>
                </select>
              </div>
              <template v-if="payload.class_type === 'weekday classes'">
                <div class="col-6">
                  <label class="form-label d-block text-start">Year Levels</label>
                  <select class="form-select" aria-label="Default select example" v-model="payload.year_level">
                    <option :value="level" :key="index" v-for="(level, index) in yearLevels['one']">{{ level }}</option>
                  </select>
                </div>
              </template>
              <template v-if="payload.class_type === 'weekend classes & summer classes'">
                <div class="col-6">
                  <label class="form-label d-block text-start">Year Levels</label>
                  <select class="form-select" aria-label="Default select example" v-model="payload.year_level">
                    <option :value="level" :key="index" v-for="(level, index) in yearLevels['two']">{{ level }}</option>
                  </select>
                </div>
              </template>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label d-block text-start">From</label>
                <select class="form-select" aria-label="Select From Month" v-model="payload.fromMonth">
                  <option :value="month" :key="month" v-for="(month, index) in months">{{ month }}</option>
                </select>
              </div>
              <div class="col-6">
                <label class="form-label d-block text-start">To</label>
                <select class="form-select" aria-label="Select To Month" v-model="payload.toMonth">
                  <option :value="month" :key="month" v-for="(month, index) in months">{{ month }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label for="intake" class="form-label d-block text-start">Intake</label>
                <input type="text" class="form-control" id="intake" v-model="payload.intake">
              </div>
              <div class="col-6">
                <label for="order" class="form-label d-block text-start">Order</label>
                <input type="text" class="form-control" id="order" v-model="payload.order">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <div class="mb-3">
                  <h4>Curriculum</h4>
                </div>
                <div class="mb-3">
                  <div class="mb-3">
                    <label for="title" class="form-label d-block text-start w-100">New title name</label>
                    <input type="text" class="form-control" id="title" v-model="src.title">
                  </div>
                  <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary" @click="addCurriculum">Add new curriculum</button>
                  </div>
                </div>
                <template v-if="payload.curriculum.length > 0">
                  <h4 class="text-start mb-3">Curriculum List</h4>
                  <div v-for="(each, curriculumIndex) in payload.curriculum"
                    class="border p-3 mb-3 rounded shadow-sm card">
                    <div class="mb-3 d-flex align-items-start justify-content-between">
                      <div class="flex flex-col w-100 me-3">
                        <div class="mb-3">
                          <label for="title" class="form-label d-block text-start">Title</label>
                          <input type="text" class="form-control me-3"
                            v-model="payload.curriculum[curriculumIndex].title">
                        </div>
                      </div>
                      <button type="button" class="btn btn-danger"
                        @click="removeCurriculum(curriculumIndex)">Remove</button>
                    </div>
                    <hr>
                    <template v-if="each.lists && each.lists.length > 0">
                      <div class="mb-3">
                        <label for="list" class="form-label d-block text-start">List</label>
                        <div class="text-start border p-3">
                          <div class="d-flex align-items-center justify-content-between mb-3"
                            v-for="(item, listItemIndex) in each.lists">
                            <input type="text" class="form-control me-3"
                              v-model="payload.curriculum[curriculumIndex].lists[listItemIndex]">
                            <button class="btn btn-danger"
                              @click="removeList(curriculumIndex, listItemIndex)">Remove</button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="mb-3 d-flex align-items-end justify-content-between">
                      <div class="w-100 me-3">
                        <label for="list" class="form-label d-block text-start">New list name</label>
                        <textarea row="2" class="form-control" id="list" v-model="src.currentList" />
                      </div>
                      <button type="button" class="btn btn-primary" @click="addList(curriculumIndex)">Add new
                        list</button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="mt-5">
                    No Curriculum Yet!
                  </div>
                </template>
              </div>
            </div>
            <div class="d-flex justify-content-end mb-3">
              <button type="button" class="btn btn-primary" :disabled="isSubmitting" @click="createCourse">{{ isSubmitting
                ? 'Creating...' : 'Create' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { months } from "@/utils/index"
import courseService from "@/services/courseApi"
import { courseConstant } from "@/constants/index"
export default {
  data() {
    return {
      months: months,
      payload: {
        class_type: 'weekday classes',
        year_level: '',
        intake: '',
        from: '',
        to: '',
        order: 1,
        curriculum: [],
      },
      src: {
        currentList: ''
      },
      courses: [],
      classTypes: courseConstant.classTypes,
      yearLevels: courseConstant.yearLevels,
      isLoading: true,
      isSubmitting: false,
    }
  },
  methods: {
    addCurriculum() {
      let data = {
        title: this.src.title ?? '',
        lists: [],
      }
      this.payload.curriculum.push(data)
      this.src.title = ''
    },
    removeCurriculum(curriculumIndex) {
      this.payload.curriculum.splice(curriculumIndex, 1)
    },
    addList(curriculumIndex) {
      if (this.src.currentList) {
        this.payload.curriculum[curriculumIndex].lists.push(this.src.currentList)
        this.src.currentList = ''
      }
    },
    removeList(curriculumIndex, listItemIndex) {
      this.payload.curriculum[curriculumIndex].lists.splice(listItemIndex, 1)
    },
    async createCourse() {
      this.isSubmitting = true
      console.log('creating course')
      const newPayload = {
        ...this.payload
      }

      // if (newPayload.from) newPayload.from = new Date(newPayload.from).toISOString()
      // if (newPayload.to) newPayload.to = new Date(newPayload.to).toISOString()
      newPayload.curriculum = JSON.stringify(newPayload.curriculum)

      try {
        let res = await courseService.createCourse(newPayload)
        console.log(res)
        this.$router.push({ name: 'CoursesRegister' })
      } catch (err) {
        console.log(err)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>