<template>
  <section class="section_2">
    <div class="Part2">
      <div class="row gx-lg-5">
        <div v-if="font_type == 1" class="col-lg-6">
          <div class="mb-4 mb-lg-5">
            <h1 class="mtext text-start">WHY SHWE MAW KUN</h1>
            <h5 class="mtext2 bold text-start">Education is important for everyone</h5>
          </div>
          <p style="text-align: justify">
            Established in January 2015, Maw Kun international Education Group is
            dedicated to delivering exceptional education in Myanmar. We provide quality
            national and international education with affordable tuition fees in the
            region. As a prestigious educational institution accredited by the Ministry of
            Education, we provide a comprehensive curriculum that encompasses essential
            subjects such as Myanmar Language, Mathematics, Science, Computing, and
            English Language. Our offerings extend to globally recognized qualifications,
            including the renowned Cambridge English and Pearson Edexcel International
            Curriculum. Furthermore, our educational approach encompasses a range of
            enriching elements, including Singapore Math, USA Curriculum (Science and
            Coding), foreign language studies, arts, music, physical education, and
            mindfulness practices. We take great pride in announcing the expansion of our
            education services to Grade 9 (Year 10) students, effective from the Academic
            Year 2024 - 2025. With a clear vision for future growth, we remain committed
            to enhancing our educational offerings in the years to come. Additionally, we
            provide professional development training for the instructors of the
            international curriculum.
          </p>
        </div>
        <div v-if="font_type == 2" class="col-lg-6">
          <h3
            style="font-size: 30px; line-height: 48px; font-weight: bold"
            class="mtext mb-5 text-start"
          >
            ရွှေမော်ကွန်း ပညာရေး မိသားစုမှ ကြိုဆိုပါတယ်
          </h3>
          <!--            <h5 class="mtext2 bold mb-5 text-start"> WE'RE MAKING EVERY-->
          <!--CHILD'S WORLD BETTER</h5>-->
          <p style="text-align: start; line-height: 30px">
            ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းသည်၂၀၁၅ခုနှစ် ဇန်နဝါရီလမှ စတင်၍
            အရည်အသွေးမြင့်ပညာရေးအတွက် Maw Kun International Education Group မှဦးစီးပြီး
            သင့်တင့်မျှတသောကျောင်းလခနှုန်းထားဖြင့် နိုင်ငံတကာပညာရေးကို
            သင်ကြားခွင့်ရရှိစေရန် မူလတန်းကြိုကျောင်းကို စတင်ဖွင့်လှစ်ခဲ့သည်။ <br />
            ယခုအခါ မြန်မာနိုင်ငံပညာရေးဝန်ကြီးဌာန အသိအမှတ်ပြု ကိုယ်ပိုင်အလယ်တန်းကျောင်းအထိ
            တိုးချဲ့ဖွင့်လှစ်ခဲ့ပါသည်။ ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင်
            မူလတန်းကြိုပညာရေး၊ မူလတန်းဆင့်၊ အလယ်တန်းဆင့်၊ ယနေ့ခေတ်တွင် မရှိမဖြစ်လိုအပ်သော
            အင်္ဂလိပ်ဘာသာစကား ကျွမ်းကျင်တတ်မြောက်မှုကို သင်ကြားပေးသည့်သင်တန်း (Cambridge
            English) နှင့် လုပ်ငန်းကျွမ်းကျင်မှုဆိုင်ရာသင်တန်း၊ ခေါင်းဆောင်မှုစွမ်းရည်
            မြှင့်တင်ရေးသင်တန်း တို့ကိုလည်းဖွင့်လှစ်ပေးခဲ့ပါသည်။
            <br />
            ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင် မြန်မာနိုင်ငံအခြေခံပညာရေးသင်ရိုးညွှန်းတမ်း၊
            နိုင်ငံတကာသင်ရိုးများဖြစ်ကြသော UK Pearson Edexcel International Curriculum ၊
            Singapore Curriculum (Maths)၊ USA Curriculum (Science and Coding) ၊
            နိုင်ငံခြားဘာသာစကား၊ ပန်းချီ၊ ဂီတ၊ အားကစား ၊ Mindfulness စသည့်
            ထပ်ဆောင်းဘာသာရပ်တို့ဖြင့် အရည်အသွေးပြည့်ဝသော ကျောင်းသူ/ သား များ ဖြစ်လာစေရန်
            ရည်ရွယ်လျက် ၂၀၂၄ -၂၀၂၅ ပညာသင်နှစ်တွင် Grade 9 (Year 10) အထိ
            ပညာသင်ကြားပေးနေပါသည်။ နောင်နှစ်များတွင်လည်း ဆက်လက်၍ အတန်းများကို
            တိုးချဲ့ဖွင့်လှစ်ပေးသွားပါမည် ဖြစ်ပါသည်။
          </p>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div class="intro mx-auto">
            <video style="width: 100%" controls>
              <source src="@/assets/videos/Shwe-Maw-Kun-Tour.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped></style>
