<template>
  <div class="text-start">
    <div class="container push-up-6">
      <section>
        <h1 class="title text-uppercase mb-5">required documents</h1>
        <div class="list-box" v-show="font_type == 1">
          <div class="list-title p-4">
            The following information must be brought to school enrollment.
          </div>
          <ul class="lists">
            <li class="px-1 py-3">Original Birth Certificate</li>
            <li class="px-1 py-3">Copy of Birth Certificate (Sighted)</li>
            <li class="px-1 py-3">Household Registration Record</li>
            <li class="px-1 py-3">Copy of Household Chart (Sighted)</li>
            <li class="px-1 py-3">Copy of Parent's National Identity Card</li>
            <li class="px-1 py-3">Original School Transfer Certificate (TC).</li>
            <li class="px-1 py-3">10 Photos of Student (Photos Taken Within 3 Months)</li>
            <li class="px-1 py-3">Student's Medical Check-Up Certificate (Within 1 Month from the Date of Application)
            </li>
            <li class="px-1 py-3">Proof of Address from Ward Administration (Original Letter Issued for School Enrollment
              Within 1 Month)</li>
          </ul>
        </div>
        <div class="list-box" v-show="font_type == 2">
          <div class="list-title p-4">
            ကျောင်းအပ်နှံရာတွင်အောက်ပါ အချက်အလက်များ ယူဆောင်လာပေးရပါမည်
          </div>
          <ul class="lists">
            <li class="px-1 py-3">မွေးစာရင်းမူရင်း</li>
            <li class="px-1 py-3">မွေးစာရင်းမိတ္တူ(Sighted)</li>
            <li class="px-1 py-3">အိမ်ထောင်စုဇယားမူရင်း</li>
            <li class="px-1 py-3">အိမ်ထောင်စုဇယားမိတ္တူ(Sighted)</li>
            <li class="px-1 py-3">မိဘ၏နိုင်ငံသားစိစစ်ရေးကဒ်မိတ္တူ</li>
            <li class="px-1 py-3">ကျောင်းပြောင်းလက်မှတ်(TC)မူရင်း</li>
            <li class="px-1 py-3">
              ကလေးဓါတ်ပုံ(၁၀)ပုံ (၃လအတွင်းရိုက်ကူးထားသော ဓါတ်ပုံ)
            </li>
            <li class="px-1 py-3">
              ကလေးဆေးစစ်ချက်လက်မှတ်(၁လအတွင်း ဆေးစစ်ထားသောလက်မှတ်)
            </li>
            <li class="px-1 py-3">
              ရပ်ကွက်ထောက်ခံစာမူရင်း(၁လအတွင်းကျောင်းအပ်ရန်အတွက်ထုတ်ပေးထားသောထောက်ခံစာ)
            </li>
          </ul>
        </div>
      </section>
      <section class="push-y-6">
        <h1 class="title sub-title text-uppercase mb-5">next what</h1>
        <div class="row text-center gy-3 gy-lg-0">
          <AdmissionLinkCard v-for="card in nextWhatCards" :key="card.id" :card="card" class="col-12 col-sm-6 col-md-4">
          </AdmissionLinkCard>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AdmissionLinkCard from "@/components/Admission/AdmissionLinkCard.vue";
export default {
  components: {
    AdmissionLinkCard,
  },
  data() {
    return {
      nextWhatCards: [
        {
          id: 0,
          title: "required documents",
          class: "image-1",
          url: "RequiredDocumentation",
        },
        { id: 0, title: "fees", class: "image-2", url: "Fees" },
        { id: 0, title: "faq", class: "image-3", url: "Faq" },
      ],
    };
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  letter-spacing: 5px;
  font-weight: var(--cus-fw-semibold);
  color: var(--cus-secondary);
}

.sub-title {
  color: var(--cus-primary);
}

.list-title {
  text-transform: uppercase;
  font-weight: var(--cus-fw-semibold);
  font-size: var(--cus-fs-xx-large);
  color: var(--cus-white);
  background-color: var(--cus-primary);
}

.list-box {
  border: 1px solid var(--cus-dark);
}

.lists {
  li {
    font-size: var(--fs-xx-large);
  }

  // li:not(:last-child) {
  //   border-bottom: 1px solid var(--cus-dark);
  // }
}

.push-up-6 {
  margin-top: 6rem;
}

.push-y-6 {
  margin: 6rem 0;
}
</style>
