<template>
  <div class="">
    <div class="Part1">
      <div class="slider_school">
        <div id="carouselExampleCaptions" class="carousel slide slider_inside" data-bs-ride="carousel"
          data-interval="100000">
          <div class="carousel-inner mb-3">
            <div class="carousel-item active" data-bs-interval="4000">
              <img src="@/assets/img/home/slider/pre.jpg" class="d-block w-100 slider_inside hero-image" alt="..." />
              <div :class="slider_class" class="d-flex" style="background: purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div v-if="font_type == 2" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Pre-School</h5>
                    </div>
                  </div>
                  <div v-if="font_type == 2" class="">
                    <div>
                      <h5 class="t_shadow pt-3 content-title d-md-none">
                        Pre-School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify; word-wrap: break-word">
                        သင်ကြားရေးနှင့် ပတ်သက်ပြီး ပြည်ထောင်စု မြန်မာနိုင်ငံတော်
                        လူမှုဝန်ထမ်းဦးစီးဌာနမှ ပြဌာန်းထားသော ECCD
                        သင်ရိုးညွှန်းတမ်းနှင့် နိုင်ငံတကာ သင်ရိုးညွှန်းတမ်း
                        စာအုပ်များကို ရွေးချယ် အသုံးပြုပါသည်။ ကလေးများ၏ ဘဝတွင်
                        မရှိမဖြစ် လိုအပ်သည့် Life Skills ၊ Mindfulness ၊
                        Montessori ၊ STEAM Education ၊ Character Education ၊ ၃၈
                        ဖြာ မင်္ဂလာကဗျာများ၊ ပုံပြင်များအပြင် Digital learning
                        Platform များကိုလည်း အသုံးပြု၍ သင်ကြားပေးနေပါသည်။
                      </p>
                      <!-- Here at Shwer Maw Kun, we are dedicated to providing
                        comprehensive Early Childhood Care and Development
                        (ECCD) programmes in Myanmar, Through our collaboration
                        with the Ministry of Social Welfare, Relief, and
                        Resttlement, we strive to create an environment where
                        children can thrive and reach their full potential. Our
                        carefully crafted curriculum encompasses a wide range of
                        enriching elements,including Life Skills, Mindfulness,
                        Montessori principles, STEAM Education, Character
                        Education, and Digital Learning Platforms. By focusing
                        on holistic development, we aim to equip our young
                        learners with the necessary skills and knowledgee to
                        navigate and succeed in the future.  -->
                    </div>
                  </div>

                  <div v-if="font_type == 1" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Pre-School</h5>
                    </div>
                  </div>
                  <div v-if="font_type == 1" class="">
                    <div>
                      <h5 class="t_shadow pt-3 content-title d-md-none">
                        Pre-School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify; word-wrap: break-word">
                        Here at Shwe Maw Kun, we are dedicated to providing
                        comprehensive Early Childhood Care and Development
                        (ECCD) programmes in Myanmar. Through our collaboration
                        with the Ministry of Social Welfare, Relief, and
                        Resettlement, we strive to create an environment where
                        children can thrive and reach their full potential. Our
                        carefully crafted curriculum encompasses a wide range of
                        enriching elements,including Life Skills, Mindfulness,
                        Montessori principles, STEAM Education, Character
                        Education, and Digital Learning Platforms. By focusing
                        on holistic development, we aim to equip our young
                        learners with the necessary skills and knowledge to
                        navigate and succeed in the future.
                      </p>
                      <!-- Here at Shwer Maw Kun, we are dedicated to providing
                        comprehensive Early Childhood Care and Development
                        (ECCD) programmes in Myanmar, Through our collaboration
                        with the Ministry of Social Welfare, Relief, and
                        Resttlement, we strive to create an environment where
                        children can thrive and reach their full potential. Our
                        carefully crafted curriculum encompasses a wide range of
                        enriching elements,including Life Skills, Mindfulness,
                        Montessori principles, STEAM Education, Character
                        Education, and Digital Learning Platforms. By focusing
                        on holistic development, we aim to equip our young
                        learners with the necessary skills and knowledgee to
                        navigate and succeed in the future.  -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="4000">
              <img src="@/assets/img/home/slider/primary.jpg" class="d-block w-100 slider_inside" alt="..." />
              <div :class="slider_class" class="d-flex" style="background: purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div v-if="font_type == 2" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Primary School</h5>
                    </div>
                  </div>
                  <div v-if="font_type == 2" class="">
                    <div>
                      <h5 class="t_shadow pt-3 content-title d-md-none">
                        Primary School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify">
                        ရွှေမော်ကွန်း ကိုယ်ပိုင်မူလတန်းကျောင်းရှိ
                        ကျောင်းသား/သူများ ငယ်ရွယ်စဉ်အချိန်တွင် Japanese ၊
                        Chinese အစရှိသည့် နိုင်ငံခြားဘာသာစကားတစ်ခုခုကို
                        ထပ်ဆောင်းအနေဖြင့် ရွေးချယ်သင်ကြားခြင်းဖြင့်
                        ဘာသာစကားအခြေခံကောင်းများ ငယ်စဉ်အရွယ်မှာ
                        လျင်မြန်စွာရရှိမည့်အပြင် နိုင်ငံတကာ၏ယဉ်ကျေးမှုနှင့်
                        Knowledge is Power ဆိုသည့်အတိုင်း ပြင်ပဗဟုသုတများ
                        တိုးပွားလာစေသည့် အကျိုးကျေးဇူးများရရှိမည် ဖြစ်ပါသည်။
                      </p>
                      <!-- Taking great pride in offering a diverse range of
                        Japanese and Chinese language courses to students of all
                        ages. Our institution places a strong focus on achieving
                        fluency and fostering cultural understanding. Our
                        language programmes provide learners with the necessary
                        tools to effectively communicate in various countries
                        where Japanese or Chinese is spoken. Ultimately, our
                        goal is to empower individuals by imparting valuable
                        knowledge and equipping them with practical skills that
                        can be applied in today's globalised world. -->
                    </div>
                  </div>

                  <div v-if="font_type == 1" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Primary School</h5>
                    </div>
                  </div>
                  <div v-if="font_type == 1" class="">
                    <div>
                      <h5 class="t_shadow pt-3 content-title d-md-none">
                        Primary School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify">
                        Taking great pride in offering a diverse range of
                        Japanese and Chinese language courses to students of all
                        ages. Our institution places a strong focus on achieving
                        fluency and fostering cultural understanding. Our
                        language programmes provide learners with the necessary
                        tools to effectively communicate in various countries
                        where Japanese or Chinese is spoken. Ultimately, our
                        goal is to empower individuals by imparting valuable
                        knowledge and equipping them with practical skills that
                        can be applied in today's globalised world.
                      </p>
                      <!-- Taking great pride in offering a diverse range of
                        Japanese and Chinese language courses to students of all
                        ages. Our institution places a strong focus on achieving
                        fluency and fostering cultural understanding. Our
                        language programmes provide learners with the necessary
                        tools to effectively communicate in various countries
                        where Japanese or Chinese is spoken. Ultimately, our
                        goal is to empower individuals by imparting valuable
                        knowledge and equipping them with practical skills that
                        can be applied in today's globalised world. -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="4000">
              <img src="@/assets/img/home/slider/sec.jpg" class="d-block w-100 slider_inside" alt="..." />
              <div :class="slider_class" class="d-flex" style="background: purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div v-if="font_type == 2" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">
                        Secondary School
                      </h5>
                    </div>
                  </div>
                  <div class="" v-if="font_type == 2">
                    <div>
                      <h5 class="t_shadow p-3 content-title d-md-none">
                        Secondary School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify">
                        ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင်
                        အခြေခံပညာသင်ရိုးနှင့် နိုင်ငံခြားသင်ရိုးတို့ကို
                        ပူးတွဲသင်ကြားပေးရုံသာမက ပြင်ပသင်ရိုးများအနေဖြင့်
                        Mindfulness ၊ Arts ၊ Physical Education ၊ Music
                        Education ၊ Coding နှင့် STEM တို့ကိုပါ
                        ထည့်သွင်းသင်ကြားပေးခြင်းကြောင့် ကျောင်းသား/သူများ ၏
                        ကိုယ်ကာယ ကျန်းမာသန်စွမ်းမှု၊ အာရုံစူးစိုက်မှုများ၊
                        ဖန်တီးမှုစွမ်းရည်၊ အနုပညာစွမ်းရည် တို့ကို ဖွံ့ဖြိုး
                        တိုးတက်စေနိုင်ပါသည်။
                      </p>
                    </div>
                  </div>

                  <div v-if="font_type == 1" class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">
                        Secondary School
                      </h5>
                    </div>
                  </div>
                  <div v-if="font_type == 1" class="">
                    <div>
                      <h5 class="t_shadow p-3 content-title d-md-none">
                        Secondary School
                      </h5>
                      <p class="t_shadow p-3 content-text" style="text-align: justify">
                        Our school is fully dedicated to delivering a
                        well-rounded education to students of all ages. We
                        firmly believe in nurturing mindfulness, arts, physical
                        education, music education, coding, and STEM subjects,
                        while providing a wide range of extracurricular
                        activities including the Student Council, English Club,
                        Sport Clubs, and Music Club. By integrating these
                        components into our curriculum, our aim is to promote
                        students' intellectual growth, enhance their creativity,
                        develop critical thinking skills, foster teamwork, and
                        facilitate personal growth. Our ultimate goal is to
                        cultivate a nurturing and stimulating learning
                        environment for our students.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
export default {
  data() {
    return {
      // font_type: localStorage.getItem("font_type"),
    };
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
    slider_class() {
      return this.$store.state.font_type == 1
        ? "slider_div_text"
        : "slider_div_text1";
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title-container {
  min-width: 300px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.content-title {
  font-size: var(--cus-fs-xx-large);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-xxx-large);
  }
}

.content-text {
  min-width: 300px;
  margin: 0;

  font-size: var(--cus-fs-normal);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-large);
  }
}

.slider_div_text {
  height: fit-content;
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;

  // @include breakpoint(xsmall) {
  //   height: 140px;
  // }

  // @include breakpoint(small) {
  //   height: 120px;
  // }

  // @include breakpoint(medium) {
  //   height: 140px;
  // }

  // @include breakpoint(large) {
  //   height: 110px;
  // }
}

.slider_div_text1 {
  height: 400px;
  position: relative;
  overflow: hidden;

  @include breakpoint(xsmall) {
    height: 340px;
  }

  @include breakpoint(small) {
    height: 320px;
  }

  @include breakpoint(medium) {
    height: 340px;
  }

  @include breakpoint(large) {
    height: 230px;
  }
}

.hero-image {
  object-position: bottom right;
}
</style>
