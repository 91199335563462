import { getAxiosClient } from "@/data/axios";

const getYear = () => {
  console.log("getting year");
  return getAxiosClient()
    .get("year")
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const updateYear = (payload) => {
  return getAxiosClient()
    .post("year-update", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export { getYear, updateYear };
