<template>
  <div class="text-start mb-5">
    <div class="container push-up-6">
      <section>
        <h1 class="title text-uppercase mb-5">frequently asked question</h1>
        <div class="list-box">
          <div class="list-title p-4">
            မိဘများ အမေးအများဆုံး မေးခွန်း (၇) ခု (FAQ)
          </div>
          <div class="lists" v-show="font_type == 1">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. What is the minimum age for a child to begin attending
                    school?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    At Shwe Maw Kun Preschool, children from the age of three
                    are accepted through the Assessment/Placement Test according
                    to the number of students determined for each class and each
                    room from the lists provided in advance.

                    <br />
                    <br />
                    Remark: We would like to inform that at Shwe Maw Kun Private
                    School, children attending the Weekend and Summer
                    International Programs are given precedence based on their
                    names.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. For the purpose of the Admission / Placement Test: What
                    specific topics are covered?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    For Pre-School Assessment, there are five areas of
                    evaluation involved: 

                    <br />
                    > Emotional Growth: Assessment of Moral Development
                    <br />
                    > Physical Development: Evaluation of Physical Advancement
                    <br />
                    > Cognitive Development: Assessment of Intellectual
                    Advancement
                    <br />
                    > Language Development: Evaluation of Linguistic Advancement
                    <br />
                    >Social Development: Check on Relationship Growth

                    <br />
                    <br />
                    The Primary & Secondary Assessment/Placement Test assesses
                    English, Maths, and Science subjects based on the curriculum
                    provided by Pearson Edexcel (UK) and the International
                    syllabus.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. What grades are taught at Shwe Maw Kun Private School?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Preschool age (Nursery) through eighth grade for National
                    Curriculum and Year 9 for International Curriculum at Shwe
                    Maw Kun Private Middle School in the school year of
                    2023-2024, with provisions to expand year on year.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    4. How do you manage the teaching of two curriculums at Shwe
                    Maw Kun School?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    When you enrol at Shwe Maw Kun School, you will receive a
                    comprehensive education that combines the national
                    curriculum approved by the Ministry of Education with an
                    international curriculum. With a team of experienced and
                    qualified educators, we offer a well-rounded education that
                    encompasses both academic excellence and international
                    standards. As for Shwe Maw Kun School, the basic curriculum
                    and international curriculum prescribed by the Ministry of
                    Education are taught at a reasonable school fee.
                    <br />

                    The curriculum prescribed by the Ministry of Education is
                    connected with No.4 Basic Education High School, Taunggyi
                    which is a prominent school in Taunggyi City. As a partner
                    school of the British Council, Shwe Maw Kun Private School
                    provides Pearson Edexcel (UK) and Cambridge English Exam
                    Preparation, ensuring that students have access to
                    internationally recognised qualifications. Our school is
                    also an authorised centre for Pearson Edexcel and Cambridge
                    English Exams, allowing students to sit for these exams
                    conveniently within our premises.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    5. What advantages do students gain from being taught two
                    distinct curriculums?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    You can transfer to any school that has a curriculum
                    prescribed by the Ministry of Education, and if you are
                    going to move to a foreign school in addition to the local
                    International School that teaches a foreign curriculum,
                    Pearson Edexcel (UK) Primary (Year 6). By offering a diverse
                    range of international schools alongside local institutions,
                    students can benefit from a well-rounded education.
                    Specifically, Shwe Maw Kun Private School offers Pearson
                    Edexcel (UK) Primary (Year 6), Lower Secondary (Year 9), and
                    IGCSE programs, ensuring that students receive globally
                    recognized qualifications. With the guidance and support of
                    qualified teachers who are well-versed in Pearson Edexcel
                    (UK) standards, students can gain valuable knowledge and
                    skills through these curriculum offerings, thereby enhancing
                    their educational journey.
                    <br />
                    <br />Note. . Please note that the admission process for the
                    school is country-specific, and there may be variations
                    depending on individual schools.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    6. What is the maximum allowable student capacity for a
                    classroom?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    In a single classroom, the maximum recommended number of
                    students is typically between 15 and 25, ensuring optimal
                    learning conditions. It allows for effective interaction
                    between teachers and students.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    7. What is the starting date of the school?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    The academic year of Pre-school consists of two semesters:
                    the June semester, which starts in June, and the December
                    semester, which starts in December. In the case of
                    elementary and middle schools, the Ministry of Education
                    determines the starting date for the June semester, and the
                    ministry sets the specific date for the commencement of
                    classes as the academic year begins in June.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lists" v-show="font_type == 2">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    ၁။ ကလေးအသက် ဘယ်အရွယ်လောက်ကနေ ကျောင်းအပ်စလက်ခံပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    ရွှေမော်ကွန်းအဆင့်မြင့်မူကြိုကျောင်းမှာ အသက် (၃) နှစ်မှစပြီး
                    ကလေးများအား ကြိုတင်ပေးထားသော အမည်စာရင်းများမှ
                    အတန်းတစ်တန်းနှင့် တစ်ခန်းချင်းစီအတွက် သတ်မှတ်ထားသော
                    ကျောင်းသားဦးရေအတိုင်း Assessment/ Placement Test စစ်ပြီး
                    လက်ခံပါသည်။ <br /><br />
                    မှတ်ချက်။ ။ ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင် Weekend နှင့်
                    Summer International Programme တွင် တက်ရောက်လေ့လာသင်ယူနေသော
                    ကလေးများအား ကြိုတင်ပေးထားသော အမည်စာရင်းများအတိုင်း
                    ဦးစားပေးလက်ခံပေးပါသည်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    ၂။ ကျောင်း၀င်ခွင့်အတွက် Assessment / Placement Test က
                    ဘယ်အချက်တွေကို အဓိကစစ်ဆေးတာပါလဲ။
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Pre-School အတွက် Assessment ကတော့ ဖွံ့ဖြိုးမှုနယ်ပယ် (၅)
                    ရပ်ကို အဓိကစစ်ဆေးပါတယ်။
                    <br />
                    ဖွံ့ဖြိုးမှုနယ်ပယ် (၅) ရပ်ကတော့<br />
                    > စာရိတ္တပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု
                    (စိတ်လှုပ်ရှားမှုဆိုင်ရာဖွံ့ဖြိုးမှု)<br />
                    > ကာယပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br />
                    > ဉာဏ်ရည်ပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br />
                    > ဘာသာစကားပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br />
                    > ပေါင်းသင်းဆက်ဆံရေးပိုင်းဆိုင်ရာဖွံဖြိုးမှု
                    များပေါ်အခြေခံပြီးစစ်ဆေးပါတယ်။<br />
                    <br />
                    Primary & Secondary Assessment/ Placement Test ကတော့ Pearson
                    Edexcel (UK) နှင့် International သင်ရိုးတို့ရဲ့
                    အဓိကဘာသာရပ်များဖြစ်တဲ့ English, Maths & Science
                    ဘာသာရပ်များကို စစ်ဆေးပါသည်
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    ၃။ ရွှေမော်ကွန်းကျောင်းမှာ ဘယ်အတန်းအထိ
                    ဖွင့်လှစ်သင်ကြားနေပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ မူကြိုအရွယ်မှစပြီး
                    မြန်မာသင်ရိုး အဌမတန်း ၊ International သင်ရိုးတို့ကို
                    ပူးတွဲပြီး Year 9 အထိ ၂၀၂၃-၂၀၂၄ ပညာသင်နှစ်မှာဖွင့်လှစ်
                    သင်ကြားလျက်ရှိပြီး ပညာသင်နှစ်တစ်နှစ်တိုင်းတွင်
                    တစ်နှစ်တစ်တန်းတိုးကာ အထက်တန်းအထိတိုးချဲ့ဖွင့်
                    လှစ်သင်ကြားပေးသွားမည်ဖြစ်ပါသည်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    ၄။ ရွှေ မော်ကွန်းကျောင်းမှာ သင်ရိုး (၂)
                    မျိုးကိုဘယ်လိုသင်ကြားပေးနေပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    ရွှေမော်ကွန်းကျောင်းကဆိုရင်
                    နိုင်ငံတကာပညာရေးကိုလက်လှမ်းမှီအောင် ပညာရေးဝန်ကြီးဌာနမှ
                    ပြဌာန်းထားသော အခြေခံပညာသင်ရိုး နဲ့ International
                    သင်ရိုးနှစ်ခုကို ပူးတွဲပြီး သင့်တင့်မျှတသောကျောင်းလခဖြင့်
                    သင်ကြားပေးနေပါသည်။ ပညာရေး၀န်ကြီးဌာနပြဌာန်းသင်ရိုး
                    အနေနဲ့ဆိုရင်တောင်ကြီးမြို့၏ အထင်ကရကျောင်းတစ်ကျောင်းဖြစ်သော
                    အ.ထ.က (၄) တောင်ကြီးကျောင်းနဲ့ ချိတ်ဆက်ထားပေးပါတယ်။ Shwe Maw
                    Kun Private School ကဆိုရင် Pearson Edexcel (UK) နှင့်
                    Cambridge English Exam Preparation တို့ ရဲ့အသိအမှတ်ပြု
                    Centre ရထားတဲ့ကျောင်းဖြစ်သည့်အပြင် British Council ၏ Partner
                    School လည်းဖြစ်ပါသည်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    ၅။ သင်ရိုး (၂) မျိုးသင်ကြားပေးတော့ ကျောင်းသား/သူ တွေအတွက်
                    ဘာအကျိုးကျေးဇူးများရှိပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    ပညာရေး၀န်ကြီးဌာနပြဌာန်းသင်ရိုးသင်သည့်မည်သည့်ကျောင်းမဆို
                    ရွှေ့ပြောင်းနိုင်ပြီး နိုင်ငံခြားသင်ရိုးသင်ကြားသော
                    ပြည်တွင်းရှိ International School အပြင် ပြည်ပရှိ
                    ကျောင်းများသို့ ရွှေ့ပြောင်းမည်ဆိုပါကလည်း Pearson Edexcel
                    (UK) ၏ Primary (Year 6) ၊ Lower Secondary (Year 9) နှင့်
                    IGCSE အစရှိသော နိုင်ငံတကာ အသိအမှတ်ပြုအောင်လက်မှတ်များကို
                    စာမေးပွဲများဖြေဆိုအောင်မြင်သူ ကျောင်းသား/ သူ အတိုင်း Pearson
                    Edexcel (UK) မှ ချီးမြှင့်မည် ဖြစ်သောကြောင့်
                    ထိုလက်မှတ်များဖြင့် ကျောင်း၀င်ခွင့် လျှောက်ထားနိုင်ပါသည်။
                    <br />
                    <br />
                    မှတ်ချက်။ ။ ကျောင်း၀င်ခွင့်မှာ နိုင်ငံအလိုက် ၊
                    ကျောင်းတစ်ကျောင်းချင်းစီပါ်မူတည်ပြီး
                    ကွာခြားမှုရှိနိုင်ပါသည်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    ၆။ စာသင်ခန်းတစ်ခန်းမှာ ကျောင်းသား/သူဦးရေ အများဆုံး
                    ဘယ်နှစ်ယောက်ထားပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    စာသင်ခန်းတစ်ခန်းမှာဆိုရင် အခန်းအကျယ်အ၀န်းပေါ်မူတည်ပြီး
                    ကျောင်းသားဦးရေ (၁၅ - ၂၅) သာ အများဆုံး လက်ခံသင်ကြားပေးပါတယ်။
                    ဆရာ/ဆရာမ နဲ့ ကျောင်းသား/သူ အချိုးကျထားရှိပါသည်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    ၇။ ကျောင်းက ဘယ်နှစ်လပိုင်းမှာစဖွင့်ပါသလဲ။
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    မူကြိုဆိုရင် တစ်နှစ်မှာ Semester နှစ်ခုရှိပါတယ်။ June
                    Semester နဲ့ December Semester ဆိုပြီး နှစ်စဉ် June
                    မှာတစ်ကြိမ် ၊ December မှာတစ်ကြိမ် ဖွင့်လှစ်ပါသည်။
                    မူလတန်းကျောင်းနဲ့ အလယ်တန်းကျောင်းကတော့
                    ပညာရေးဝန်ကြီးဌာနကသတ်မှတ်ထားတဲ့အတိုင်း နှစ်စဉ် June လမှာ
                    ဖွင့်လှစ်ပါသည်။
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  letter-spacing: 5px;
  font-weight: var(--cus-fw-semibold);
  color: var(--cus-secondary);
}

.sub-title {
  color: var(--cus-primary);
}

.list-title {
  text-transform: uppercase;
  font-weight: var(--cus-fw-semibold);
  font-size: var(--cus-fs-xx-large);
  color: var(--cus-white);
  background-color: var(--cus-primary);
}

.list-box {
  // border: 1px solid var(--cus-dark)
}

.accordion-item:first-of-type,
.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-button {
  border-radius: 0 !important;
}

.accordion-body {
  white-space: pre-wrap;
  line-height: 30px;
}

.lists {
  div {
    font-size: var(--fs-xx-large);
  }
}

.push-up-6 {
  margin-top: 6rem;
}
</style>
