import { getAxiosClient } from "@/data/axios";

const getFees = () => {
  console.log("getting fees");
  return getAxiosClient()
    .get("fees")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
const createFees = (payload) => {
  return getAxiosClient()
    .post("fees", payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
const updateFees = (id, payload) => {
  return getAxiosClient()
    .post("fees-update/" + id, payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
const deleteFees = (id) => {
  return getAxiosClient()
    .post("fees-delete/" + id)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export { getFees, createFees, deleteFees, updateFees };
