<template>
  <div class="text-start mb-5" v-show="font_type == 1">
    <h2 class="title text-uppercase mb-3">Educational Opportunity</h2>

    <div class="content_container">
      <p class="content">
        In addition to both international curricula set by the Ministry of
        Education and taught by the school, students at Shwe Maw Kun School have
        the opportunity to fully learn additional subjects in order to create
        the educational qualities needed in the twenty-first century.
      </p>
      <p class="content">
        Shwe Maw Kun School has developed good leaders who are responsible and
        accountable as well as caring, hopeful, capable of cooperating,
        ingenious, and imaginative with one another, experiencing learning
        music, we are striving to produce experts in several domains of
        specialty, such as sports.
      </p>
    </div>
  </div>
  <div class="text-start mb-5" v-show="font_type == 2">
    <h2 class="title text-uppercase mb-3">
      ကျောင်းသား/သူများ ရရှိနိုင်သည့် အခွင့် အရေး
    </h2>

    <div class="content_container">
      <p class="content">
        ရွှေမော်ကွန်းကျောင်းတွင် မူကြို မှ အလယ်တန်းအထိ သင်ကြားမှု ပြုလုပ်ပေးပြီး
        ကျောင်းသား/ ကျောင်းသူများမှာလည်း ကျောင်းမှ သင်ကြားပေးသော
        ပညာရေးဝန်ကြီးဌာနမှ ပြဌာန်းထားသော အခြေခံပညာသင်ရိုး နှင့်
        နိုင်ငံတကာသင်ရိုး ၂ခုစလုံး အပြင် ၂၁ရာစုခေတ်တွင်လိုအပ်သော
        ပညာအရည်အသွေးများကို ဖန်တီးပေးနိုင်ရန်အတွက် ထပ်ဆောင်းဘာသာရပ်များကိုပါ
        အပြည့်အဝ သင်ယူနိုင်သည့် အခွင့် အရေးရရှိခြင်း။
      </p>
      <p class="content">
        ရွှေမော်ကွန်းကျောင်းမှ တာဝန်ယူမှု တာဝန်ခံမှု ရှိသော ခေါင်းဆောင်ကောင်း၊
        ကိုယ်ချင်းစာစိတ် နှင့် အကောင်းမြင်ဝါဒ ရှိသောနိုင်ငံသားကောင်း ၊
        ပူးပေါင်းပါဝင်ဆောင်ရွက်နိုင်သူ၊ အပြန်အလှန် ပြောဆို ဆက်သွယ်
        နိုင်စွမ်းရှိသူ၊ တီထွင် ဖန်တီးနိုင်သူ၊ စူးစမ်း လေ့လာ နိုင်စွမ်းရှိသူ၊
        ဂီတ၊ အားကစား အစရှိသော မိမိတို့ ထူးချွန်ရာ နယ်ပယ်တွင် ကျွမ်းကျင်သော
        ပညာရှင် မွေးထုတ်ပေးနိုင်ရန် ဆောင်ရွက် နေပါသည်။
      </p>
    </div>
  </div>

  <div class="row gy-3 gy-lg-0">
    <AdmissionLinkCard
      v-for="card in benefitCards"
      :key="card.id"
      :card="card"
      class="col-12 col-sm-6 col-lg-3"
    >
    </AdmissionLinkCard>
  </div>
</template>

<script>
import AdmissionLinkCard from "@/components/Admission/AdmissionLinkCard.vue";
export default {
  components: {
    AdmissionLinkCard,
  },
  data() {
    return {
      benefitCards: [
        {
          id: 0,
          title: "required documents",
          class: "image-1",
          url: "RequiredDocumentation",
        },
        { id: 0, title: "fees", class: "image-2", url: "Fees" },
        { id: 0, title: "faq", class: "image-3", url: "Faq" },
        { id: 0, title: "waitlist form", class: "image-4", url: "waitinglist" },
      ],
    };
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--cus-secondary-bold);
  font-weight: var(--cus-fw-semibold);
  text-align: left;
}

.content_container {
  p {
    text-align: justify;
  }
}

.content {
  line-height: 30px;
  text-indent: 30px;
}
</style>
