<template>
  <div>
    <div class="container my-3">
      <div class="row d-flex justify-content-center my-5">
        <div class="col-12 pt-4">
          <h1 class="text-start" style="color:goldenrod"><b> FOR CONTACT US </b> </h1>
          <h5 class="text-start" style="color:purple">SOCIAL MEDIA</h5>
          <img src="./../assets/img/contact_top.png" style="width:100%;" alt="">
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank" href="https://www.facebook.com/MawKunInternationalEducationGroupMyanmar?mibextid=ZbWKwL"
                class="about_contact">
                <img src="../assets/img/facebook.png" alt="facebook icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank" href="https://instagram.com/smk_education_group?igshid=YmMyMTA2M2Y="
                class="about_contact">
                <img src="../assets/img/insta.png" alt="instagram icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank" href="https://youtube.com/@shwemawkunsmkinternational9122" class="about_contact">
                <img src="../assets/img/youtube.png" alt="youtube icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank" href="https://t.me/shwemawkuneducationgroup" class="about_contact">
                <img src="../assets/img/teleg.png" alt="telegram icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank"
                href="https://invite.viber.com/?g2=AQA33LOWH3ZcM0%2BtyMUiATIehtC7nm7HaMwzBZfKJKLmbyjENzS88RQTRgOIJAKD"
                class="about_contact">
                <img src="../assets/img/viber.png" alt="viber icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4 p-2 px-5">
              <a target="_blank" href="https://www.linkedin.com/company/shwe-maw-kun-education-group"
                class="about_contact">
                <img src="../assets/img/linkin.png" alt="linkedin icon">
                <h5 v-if="font_type == 1">SHWE MAW KUN</h5>
                <h5 v-if="font_type == 2">ရွှေမော်ကွန်း</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <nav>
                <div class="nav nav-tabs d-flex justify-content-center" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-Content" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Content</button>
                    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-Map" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Map</button>
                </div>
            </nav> -->
      <div class="row d-flex justify-content-center mb-5 gy-5">
        <div class="col-12 col-lg-6">
          <div class="container">
            <div class="w-auto text-start mb-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="admission" id="admission1" v-model="admission"
                  value="admission">
                <label class="form-check-label" for="admission1">
                  Admission (New Parents)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="admission" id="admission2" v-model="admission"
                  value="general">
                <label class="form-check-label" for="admission2">
                  General
                </label>
              </div>
            </div>

            <h1 v-if="font_type == 1" class="text-start" style="color:goldenrod"><b>ENQUIRE NOW</b></h1>
            <h3 v-if="font_type == 2" class="text-start mb-3" style="color:goldenrod"><b>ကျောင်းကိစ္စ စုံစမ်းရန်</b></h3>

            <input type="text" v-model="name" required class="contact_us_input form-control" name="name" id="name"
              :placeholder="font_type == 1 ? 'Name' : 'အမည်'">
            <input type="text" class="contact_us_input form-control" required v-model="phone" name="phone" id="phone"
              :placeholder="font_type == 1 ? 'Mobile' : 'ဖုန်းနံပါတ်'">
            <input type="text" class="contact_us_input form-control" required name="email" v-model="email" id="email"
              :placeholder="font_type == 1 ? 'Email' : 'အီးမေးလ်လိပ်စာ'">
            <input type="text" v-model="subject" required class="contact_us_input form-control" name="subject"
              id="subject" :placeholder="font_type == 1 ? 'Subject' : 'အကြောင်းအရာ'">
            <textarea name="content" class="contact_us_input form-control" required id="content" v-model="content"
              cols="30" rows="10" :placeholder="font_type == 1 ? 'Message' : 'မှတ်ချက်'"></textarea>
            <button class="btn btn-warning" @click="sendMail">
              <div v-if="isloading == true" class="spinner-border" role="status">
              </div> <span v-if="isloading == false"> SUBMIT </span>
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div v-if="font_type == 1" class="bg-warning p-5 text-start">
            <h3><b>TELEPHONE</b></h3>
            <h5>09-252447066</h5>
            <h5>
              09-428377766
            </h5>
            <br>
            <br>
            <h3><b>EMAIL</b></h3>
            <h5 class="email">
              shwemawkunschool@gmail.com
            </h5>
            <br><br>
            <h3><b>OPERATIONAL HOURS</b></h3>
            <h5>Monday - Friday: 9am to 4pm</h5>
            <br><br>

            <h3><b>SCHOOL ADDRESS</b></h3>
            <h5><b>SCHOOL ADDRESS (1)</b></h5>
            <p>
              No. 20/10,Shwe Chi Street, (Corner of Yae Htwet Oo Street), Yae Aye Kwin Quarter, Taunggyi, Shan State,
              Myanmar
            </p>
            <h5><b>SCHOOL ADDRESS (2)</b></h5>
            <p>
              No. 20/10,Shwe Chi Street, (Corner of Yae Htwet Oo Street), Yae Aye Kwin Quarter, Taunggyi, Shan State,
              Myanmar
            </p>
          </div>
          <div v-if="font_type == 2" class="bg-warning p-5 text-start">
            <h4><b>ဖုန်းနံပါတ်</b></h4>
            <p> ၀၉-၂၅၂၄၄၇၀၆၆</p>
            <p>၀၉-၄၂၈၃၇၇၇၆၆</p>
            <br>
            <br>
            <h3><b>အီးမေးလ်လိပ်စာ</b></h3>
            <h5 class="email">
              shwemawkunschool@gmail.com
            </h5>
            <br><br>
            <h4><b>ရုံးဖွင့်ချိန်</b></h4>
            <p>တနင်္လာနေ့ မှ သောကြာနေ့ ထိ ။(နံနက် ၉ နာရီမှ ညနေ ၄နာရီထိ)</p>
            <br><br>

            <h4><b>ကျောင်းလိပ်စာ</b></h4>
            <p>
              အမှတ်(၂၀/၁၀)၊ ရွှေခြည်လမ်း နှင့် ရေထွက်ဦး လမ်းထောင့်၊ ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။
            </p>
          </div>
        </div>
      </div>
      <h1 class="text-start mt-4 mb-4" style="color:purple"> <b>{{ font_type == 1 ? 'OUR ENLARGE MAP' : 'အချက်အလက်ပြဇယား'
      }}
        </b> </h1>
      <div class="row flex-column flex-lg-row justify-content-center gx-0 mb-5">
        <div class="cus-info-container col py-5 px-4 border shadow-lg d-flex justify-content-center">
          <div class="cus-info">
            <h3> {{ font_type == 1 ? 'FIND US IN' : 'တည်နေရာ' }}</h3>
            <p>
              {{ font_type == 1 ? 'Taunggyi, Southern Shan State,Myanmar' :
                'မြန်မာနိုင်ငံ၏ ရှမ်းပြည်နယ်တောင်ပိုင်း ၊ တောင်ကြီးမြို့။ ' }}

            </p>
            <br>
            <h3> {{ font_type == 1 ? 'OPERATIONAL HOURS' : 'ရုံးဖွင့်ချိန်' }}</h3>
            <p>{{ font_type == 1 ? '9:00am-4:00pm' : 'နံနက် ၉ နာရီမှ ညနေ ၄နာရီထိ' }} </p>
            <br>
            <h3> {{ font_type == 1 ? 'OUR LOCATION' : 'ကျောင်းတည်နေရာများ' }} </h3>
            <br>
            <p>
              <b>{{ font_type == 1 ? 'Pre-school' : 'ရွှေမော်ကွန်းအဆင့်မြင့်မူလတန်းကြိုကျောင်း' }}</b>
              {{ font_type == 1 ? ' No. 35/9, Ngwe Kyal Phyu Street,Yae Aye Kwin Ward, Taunggyi' :
                'အမှတ်(၃၅/၉)၊ ငွေကြယ်ဖြူလမ်း၊ ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။' }}
            </p>
            <br>
            <p>
              <b> {{ font_type == 1 ? 'Primary school' : 'ရွှေမော်ကွန်း ကိုယ်ပိုင်မူလတန်းကျောင်း' }}</b>
              {{ font_type == 1 ? ' No. 20/10 Shwe Chi Street (Corner of Ye Htwet Oo Street), Yae Aye Kwin Ward,Taung-gyi'
                :
                ' အမှတ်(၂၀/၁၀)၊ ရွှေခြည်လမ်း နှင့် ရေထွက်ဦး လမ်းထောင့်၊ ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။' }}
            </p>
            <br>
            <p>
              <b>{{ font_type == 1 ? 'Secondary school' : 'ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်း' }} </b>
              {{ font_type == 1 ?
                ' No.27/10, Shwe Chi Street, Yae Aye Kwin Ward, Taunggyi' :
                ' အမှတ် (၂၇/၁၀) ၊ ရွှေခြည်လမ်း ၊ ရေအေးကွင်းရပ်ကွက် ၊ တောင်ကြီးမြို့ ။' }}
            </p>
          </div>
        </div>

        <div class="cus-form-container col border shadow-lg bg-body">
          <!-- <form action=""> -->
          <div style="width:100%;height: 750px;">
            <iframe style="width:100%;height:100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3730.0544492201007!2d97.04303304987813!3d20.78908498606058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ce864c0e6a5fc3%3A0xb4563bd2d1fdb904!2sShwe%20Maw%20Kun%20Private%20School%2F%20Maw%20Kun%20International%20Education%20Group!5e0!3m2!1sen!2smm!4v1669694121304!5m2!1sen!2smm"
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-Content" role="tabpanel" aria-labelledby="nav-home-tab"
              tabindex="0">
            </div>
            <div class="tab-pane fade  " id="nav-Map" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      link: 'https://backend.smkedugroup.com',
      name: '',
      email: '',
      address: '',
      phone: '',
      subject: '',
      content: '',
      isloading: false,
      admission: 'admission',
    }
  },
  computed: {
    font_type() {
      return this.$store.state.font_type
    }
  },
  methods: {
    sendMail() {
      if (this.name == '') {
        return document.getElementById('name').focus();
      }
      else if (this.email == '') {
        return document.getElementById('email').focus();
      }
      else if (this.phone == '') {
        return document.getElementById('phone').focus();
      }
      else if (this.subject == '') {
        return document.getElementById('subject').focus();
      }
      else if (this.content == '') {
        return document.getElementById('content').focus();
      }
      this.isloading = true;
      let article =
      {
        Name: this.name,
        Email: this.email,
        Address: this.address,
        Phone: this.phone,
        Subject: this.subject,
        Content: this.content,
        Admission: this.admission,
      };

      axios.post(this.link + "/api/sendmail", article)
        .then(response => {
          this.isloading = false;
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass: 'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          })
          // window.location.assign('');
        })
        .catch(error => {
          this.isLoading = false;
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
    },
  },
}
</script>

<style lang="scss" scoped >
.email {
  word-wrap: break-word;
}

.about_contact {
  overflow: hidden;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  border: 1px #0071bb;
  border-style: solid;
  border-radius: 20px;

  img {
    width: 50px;
    height: 50px;
  }

  h5 {
    margin: 0 0 0 20px;
  }
}

.contact_us_input {
  border: 1px black;
  border-style: solid;
  border-radius: 0px;
  margin-bottom: 30px;
}

.cus-form-container {
  height: 500px;
}

.cus-info-container {
  background-color: purple;
  color: white;
  height: 750px;
  
  @include breakpoint(large) {
    max-width: 500px;
  }
}
</style>
