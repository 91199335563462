import { getAxiosClient } from "@/data/axios";

export const getCourses = (payload) => {
  console.log("getting fees", payload);
  return getAxiosClient()
    .get("courses", payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCourse = (payload) => {
  console.log("getting fees", payload);
  return getAxiosClient()
    .get(`courses/${payload.id}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

const createCourse = (payload) => {
  return getAxiosClient().post("courses", payload).then(res => {
    console.log(res.data)
    return res.data
  }).catch(error => {
    throw error
  })
} 

const updateCourse = (payload) => {
  return getAxiosClient().put(`courses/${payload.id}`, payload).then(res => {
    console.log(res.data)
    return res.data
  }).catch(error => {
    throw error
  })
} 

const deleteCourse = (payload) => {
  return getAxiosClient().delete(`courses/${payload.id}`).then(res => {
    console.log(res.data)
    return res.data
  }).catch(error => {
    throw error
  })
} 

const courseService = {
  getCourses,
  getCourse,
  createCourse,
  deleteCourse,
  updateCourse
}

export default courseService