<template>
  <div>
    <h5 class="mb-4 list">
      <template v-if="font_type == 1">
        The following fees are not included in School Fees
      </template>
      <template v-if="font_type == 2">
        The following fees are not included in School Fees
      </template>
    </h5>

    <ul>
      <li v-for="(item, index) in list" :key="index">{{ item.name }}</li>
    </ul>
  </div>
  <div class="image"></div>
</template>

<script>
import { getYear } from "@/services/year.js";

export default {
  data() {
    return {
      list: [],
    };
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
  methods: {},
  async mounted() {
    // this.list = [...response.data.discription];
    // year
    let res = await getYear();
    console.log(res);
    this.list = [...res.data.discription];
  },
};
</script>

<style lang="scss" scoped>
.image {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("@/assets/img/photo/08.png");
  height: 250px;
}

.list {
  font-weight: var(--cus-fw-semibold);
}
</style>
