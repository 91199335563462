<template>
  <div class="">
    <h1 class="fw-bold mtext">Voices of Parents and Students:</h1>
    <h3 v-if="font_type == 1" class="mtext3">Shwe Maw Kun School Experience</h3>
    <h3 v-if="font_type == 2" class="mtext3 mb-3" style="line-height: 40px;">
      မိဘများနှင့် ကျောင်းသား/ကျောင်းသူများ၏ စကားသံများ
    </h3>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-sm-12">
      <video style="width: 100%" controls>
        <source src="@/assets/videos/Shwe-Maw-Kun-Interview.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped></style>
