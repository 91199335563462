<template>
  <div class="row gy-3 gy-lg-0">
    <div class="col-12 col-lg-6">
      <div class="accordion cus-accordion" id="preSchool">
        <div class="accordion-item mb-3">
          <h2 class="accordion-header" id="preSchoolHeading">
            <button
              class="accordion-button collapsed course-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#preSchoolContent"
              aria-expanded="false"
              aria-controls="preSchoolContent"
            >
              pre-school
            </button>
          </h2>
          <div
            id="preSchoolContent"
            class="accordion-collapse collapse"
            aria-labelledby="preSchoolHeading"
          >
            <div class="accordion-body bg-white">
              <div
                class="course-group"
                v-for="(list, index) in preList"
                :key="index"
              >
                <div class="row align-items-center p-3">
                  <div class="col-8">
                    <h4 class="course text-uppercase">{{ list.from_year }}</h4>
                  </div>
                  <div class="col-4">
                    <h4 class="price text-uppercase">{{formatNumber(list.charges) }} MMK</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item mb-3">
          <h2 class="accordion-header" id="primarySchoolHeading">
            <button
              class="accordion-button collapsed course-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#primarySchoolContent"
              aria-expanded="false"
              aria-controls="primarySchoolContent"
            >
              primary school
            </button>
          </h2>
          <div
            id="primarySchoolContent"
            class="accordion-collapse collapse"
            aria-labelledby="primarySchoolHeading"
          >
            <div class="accordion-body bg-white">
              <div
                class="course-group"
                v-for="(list, index) in primaryList"
                :key="index"
              >
                <div class="row align-items-center p-3">
                  <div class="col-8">
                    <h4 class="course text-uppercase">{{ list.from_year }}</h4>
                  </div>
                  <div class="col-4">
                    <h4 class="price text-uppercase">{{formatNumber(list.charges) }} MMK</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="secondarySchoolHeading">
            <button
              class="accordion-button collapsed course-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#secondarySchoolContent"
              aria-expanded="false"
              aria-controls="secondarySchoolContent"
            >
              secondary school
            </button>
          </h2>
          <div
            id="secondarySchoolContent"
            class="accordion-collapse collapse"
            aria-labelledby="secondarySchoolHeading"
          >
            <div class="accordion-body bg-white">
              <div
                class="course-group"
                v-for="(list, index) in secondaryList"
                :key="index"
              >
                <div class="row align-items-center p-3">
                  <div class="col-8">
                    <h4 class="course text-uppercase">{{ list.from_year }}</h4>
                  </div>
                  <div class="col-4">
                    <h4 class="price text-uppercase">{{formatNumber(list.charges) }} MMK</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- right side -->
    <div class="col-12 col-lg-6">
      <Document></Document>
    </div>
  </div>
</template>

<script>
import { getFees } from "@/services/fee.js";
import { formatNumber } from "../../utils/index"
import Document from "@/components/Fees/Document.vue";
export default {
  components: {
    Document,
  },
  data() {
    return {
      classList: [],
      preList: [],
      primaryList: [],
      secondaryList: [],
    };
  },
  methods: {
    formatNumber,
    async getFee() {
      try {
        let response = await getFees();
        console.log(response);
        this.allFees = [...response];
        this.allFees.map((e) => {
          console.log(e.class_types);
          if (e.class_types == 1) {
            this.classList.push(e);
          }
        });
        console.log(this.classList);
        this.classList.map((e) => {
          if (e.fees_types == 1) {
            this.preList.push(e);
          } else if (e.fees_types == 2) {
            this.primaryList.push(e);
          } else {
            this.secondaryList.push(e);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
      }
      // axios
      //   .get(this.link + "/api/fees")
      //   .then((response) => {
      //     console.log(response.data);
      //     this.allFees = [...response.data];
      //     console.log(response);
      //     this.allFees.map((e) => {
      //       console.log(e.class_types);
      //       if (e.class_types == 1) {
      //         this.classList.push(e);
      //       }
      //     });
      //     this.classList.map((e) => {
      //       if (e.fees_types == 1) {
      //         this.preList.push(e);
      //       } else if (e.fees_types == 2) {
      //         this.primaryList.push(e);
      //       } else {
      //         this.secondaryList.push(e);
      //       }
      //     });
      //     console.log(this.classList);
      //   })
      //   .catch((error) => {
      //     this.errorMessage = error.message;
      //     console.error("There was an error!", error);
      //   });
    },
  },
  mounted() {
    this.getFee();
  },
};
</script>

<style lang="scss" scoped>
.image {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("@/assets/img/photo/06.png");
  height: 250px;
}

.cus-accordion {
  .accordion-item {
    border: 0;
  }

  .accordion-body {
    padding: 0;
    margin-top: -5px;
  }

  .accordion-button {
    text-transform: uppercase;
    font-weight: var(--cus-fw-semibold);
    background-color: var(--cus-primary);
    color: var(--cus-white);
    font-size: var(--fs-xxx-large);
    border-radius: var(--cus-border-radius);
    position: relative;

    &.collapsed {
      border-radius: var(--cus-border-radius);
    }

    &::after {
      color: var(--cus-white) !important;
      // chevron down icon from bootstrap
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-size: 1.5rem 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      right: 4%;
      transform: translateY(-50%) rotate(180deg);

      @include breakpoint(medium) {
        background-size: 3rem 3rem;
        width: 3rem;
        height: 3rem;
        right: 2%;
      }
    }

    &:not(.collapsed)::after {
      transform: translateY(-50%);
    }
  }

  .accordion-collapse {
    width: 95%;
    margin: 0 auto;
    border: 2px solid var(--cus-primary);
    border-radius: 0;
  }
}

.list {
  font-weight: var(--cus-fw-semibold);
}

.price {
  // white-space: nowrap;
  font-size: var(--cus-fs-normal);
  font-weight: var(--cus-fw-semibold);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-x-large);
  }
}

.course {
  font-size: var(--cus-fs-normal);
  font-weight: var(--cus-fw-semibold);

  @include breakpoint(small) {
    font-size: var(--cus-fs-x-large);
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.course-group {
  overflow-x: scroll;

  @include breakpoint(medium) {
    overflow: hidden;
  }

  &:not(:last-child) {
    border-bottom: 3px solid var(--cus-primary);
  }
}

.course-button {
  font-size: var(--cus-fs-normal) !important;

  @include breakpoint(medium) {
    font-size: var(--cus-fs-x-large) !important;
  }
}
</style>
