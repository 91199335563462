// import { DateTime } from "luxon"

export const cusFormatDate = function (date) {
	let newDate = date

  if (date) {
    newDate = new Date(date).toISOString()
  } else {
    newDate = new Date().toISOString()
  }
	// return localDateTimeInMyanmar.toString().split("T")[0]

  return newDate.split("T")[0]
}

export const formatNumber = function(number) {
	return number.toLocaleString("en-US")
}

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];