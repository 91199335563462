import { createStore } from 'vuex'

export default createStore({
	state: {
		footer: true,
		font_type: 1,
	},
	getters: {},
	mutations: {},
	actions: {
		changeFont({state}, font_type) {
			state.font_type = font_type
		}
	},
	modules: {},
});
