<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 500px"
  >
    <div>
      <img
        src="../../assets/img/smkloading.gif"
        width="150"
        height="150"
        alt=""
      />
      <div>Loading...</div>
    </div>
    <!-- <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
