<template>
  <div class="card_container">
    <div class="card cus_card">
      <div class="card-body p-1 p-lg-5">
        <div class="content_container shadow" v-show="font_type == 1">
          <h1 class="text-uppercase">School Enrollment Programme</h1>
          <p class="leading-3 indent-px">
            Shwe Maw Kun is delighted to offer captivating weekend and summer
            international programmes that cater to the diverse needs of
            students, fostering their academic growth and overall development.
            Our programmes provide an exceptional platform for students to
            engage in enriching experiences and enhance their educational
            journey. Carefully tailored to address specific student
            requirements, our weekend and summer programmes create an
            environment where students can learn, flourish, and excel. We
            provide opportunities for students to explore new subjects, develop
            essential skills, and actively participate in interactive learning
            activities. Maintaining a strong focus on individual attention and
            personalised instruction, we ensure a low student-to-teacher ratio,
            students enrolled in the Weekend and Summer International Programs
            will be given first priority if dropouts occur among current
            students. Prospective students are also welcome to take the entrance
            exam and to get involved in school activities. New student
            acceptance is based on optimism and parental collaboration.Our
            dedicated team of educators is passionate about nurturing every
            student's potential, instilling a love for learning, and fostering
            their intellectual development. Moreover, we understand the
            significance of holistic growth and the value of extracurricular
            activities. Our programmes offer a well-rounded approach, seamlessly
            integrating recreational and creative pursuits with academic
            endeavours. Students can engage in sports, arts, and other
            stimulating activities that promote their physical, emotional, and
            social well-being.
          </p>
          <p class="leading-3 indent-px">
            Siblings of existing Shwe Maw Kun School pupils are given priority
            for students who are three years old and will attend Pre-school.
            There will be a limited number of pupils accepted, and enrollment
            will be based on a screening of external students/waitlists,
            optimism, and parental collaboration. Given the limited availability
            of spaces, we encourage interested students and parents to register
            early to secure a place in our programmes. In cases where all spaces
            are filled, we also provide a waitlist option. We ensure fairness
            and transparency in managing the waitlist, prioritising students who
            demonstrate a genuine interest in our programmes. At Shwe Maw Kun,
            we are fully committed to providing a nurturing and enriching
            educational experience. Our weekend and summer programmes empower
            students to realise their full potential, equipping them with the
            skills and knowledge necessary for success. Join us as we embark on
            an inspiring journey of learning and growth.
          </p>
        </div>
        <div class="content_container shadow" v-show="font_type == 2">
          <h1 class="text-uppercase">ကျောင်းအပ်လက်ခံရေးအစီအစဉ်</h1>
          <p class="content">
            ရွှေမော်ကွန်းကျောင်းတွင် ကျောင်းသားဦးရေ ကန့် သတ်ထားမှုကြောင့် လက်ရှိ
            တက်ရောက်နေသော ကျောင်းသား/သူများ အနက် ကျောင်းထွက်သူရှိမှ Weekend
            နှင့် Summer International Programme တွင် တက်ရောက်နေသော
            ကျောင်းသား/သူများ ကို ပထမဦးစားပေးအနေဖြင့်လည်းကောင်း၊
            ပြင်ပကျောင်းသား/သူကိုလည်း ဝင်ခွင့်စာမေးပွဲ ဖြေဆိုအောင်မြင်ပြီး
            ကျောင်း၏ လုပ်ငန်းဆောင်တာ များတွင် ပူးပေါင်းပါဝင်နိုင်မှု၊
            အကောင်းမြင်ဝါဒရှိမှု နှင့် မိဘ ပူးပေါင်း ဆောင်ရွက်နိုင်မှုအပေါ်
            မူတည်ပြီး ကျောင်းသားသစ်ကို ကျောင်းအပ်လက်ခံမှု ပြုလုပ်သည်။
          </p>
          <p class="content">
            အသက်(၃)နှစ်ပြည့်ပြီး မူလတန်းကြိုတက်ရောက်မည့်
            ကျောင်းသား/ကျောင်းသူများ အတွက်လည်း ရွှေ မော်ကွန်းကျောင်းတွင် လက်ရှိ
            တက်ရောက်နေသော ကျောင်းသား/သူများ၏ မွေးချင်း မောင်နှမများကို အဓိက
            ဦးစားပေး လက်ခံပါသည်။ ကျောင်းသား/ကျောင်းသူများကို အကန့် အသတ်နှင့်သာ
            လက်ခံမည် ဖြစ်သည့် အတွက် ပြင်ပ ကျောင်းသား/ကျောင်းသူများ ၏ Waitlist
            ကို စီစစ်အကဲဖြတ်မှု ၊အကောင်းမြင်ဝါဒနှင့် မိဘ ပူးပေါင်း
            ဆောင်ရွက်မှုပေါ် မူတည်ပြီး ကျောင်းအပ် လက်ခံမှု ပြုလုပ်သည်။
          </p>
        </div>
        <div class="image_contianer">
          <div class="image" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.cus_card {
  width: 100%;
  background-color: var(--cus-primary);
  border-radius: 0 !important;
}

.content_container {
  position: static;
  z-index: 20;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--cus-secondary);
  margin-bottom: 1.5rem;

  @include breakpoint(large) {
    width: 55%;
    position: relative;
    padding: 30px 30px;
    margin-bottom: 0;
  }

  h1 {
    font-weight: bolder;
    margin-bottom: 30px;
  }

  p {
    text-align: justify;
  }
}

.image {
  background-image: url("@/assets/img/photo/07.JPG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: static;
  width: auto;
  height: 240px;

  @include breakpoint(medium) {
    height: 400px;
  }

  @include breakpoint(large) {
    background-position: 70% center;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    right: 48px;
    position: absolute;
    padding: 30px 30px;
    width: 550px;
    height: 550px;
  }
}

img {
  z-index: 5;
}

.content {
  line-height: 30px;
  text-indent: 30px;
}
</style>
